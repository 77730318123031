import Text from 'components/Text';
import { observer } from 'mobx-react-lite';
import React from 'react';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';

const AvatarImg = styled.img`
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.palette.primary[500]};
`;

const AvatarInitials = styled(Text)`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.palette.primary[500]};
  background-color: ${(props) => props.theme.palette.primary[300]};
  color: ${(props) => props.theme.palette.light[50]};
`;

const Avatar: React.FC = () => {
  const { authStore } = useStores();

  if (!authStore.profile) {
    return null;
  }

  if (!authStore.profile.avatar) {
    const { firstName, lastName } = authStore.profile;
    return (
      <AvatarInitials variant="h7">{`${firstName[0]}${lastName[0]}`}</AvatarInitials>
    );
  }

  return <AvatarImg src={authStore.profile.avatar} alt="User avatar" />;
};

export default observer(Avatar);
