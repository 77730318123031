import { createUser } from 'api/user';
import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Container from 'components/Container';
import Footer from 'components/Footer';
import FormikInput from 'components/FormikInput';
import Header from 'components/Header';
import Navbar from 'components/Nav/Navbar';
import { Formik, FormikHelpers } from 'formik';
import useAlert from 'hooks/useAlert';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { ROUTE } from 'utils/routes';
import * as Yup from 'yup';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email address')
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
});

const NewUser: React.FC = () => {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!authStore.profile?.admin) {
      navigate(ROUTE.HOME);
    }
  }, [authStore.profile?.admin]);

  const onSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    setLoading(true);
    try {
      await createUser({ user: values });
      alert.success('User successfully created');
      resetForm();
    } catch (e) {
      alert.error('Failed to create a user');
    }
    setLoading(false);
  };

  return (
    <>
      <Navbar />
      <Header
        back
        noBorder
        title="Create a new user"
        description="Enter details for the new user and create a profile for them."
      />

      <Formik
        initialValues={{ email: '', firstName: '', lastName: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <StyledContainer>
              <FormikInput
                name="email"
                label="Email"
                type="email"
                placeholder="Enter email"
              />

              <FormikInput
                name="firstName"
                label="First name"
                type="text"
                placeholder="Enter first name"
              />

              <FormikInput
                name="lastName"
                label="Last name"
                type="text"
                placeholder="Enter last name"
              />
            </StyledContainer>

            <Footer noBorder>
              <Button type="submit" width="m" disabled={!dirty || !isValid}>
                Create a user
              </Button>
            </Footer>
          </form>
        )}
      </Formik>

      {loading && <AppLoader />}
    </>
  );
};

export default NewUser;
