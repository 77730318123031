import { ReactComponent as JerseyIcon } from 'assets/jersey.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import Button from 'components/Button';
import Avatar from 'components/Nav/Avatar';
import NavLink from 'components/Nav/NavLink';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  padding: 14px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.primary[700]};
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledLogo = styled(Logo)`
  display: block;
`;

const Navbar: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authStore, playerStore } = useStores();

  const isAdmin = !!authStore.profile?.admin;

  return (
    <Wrapper>
      <Link to={ROUTE.HOME}>
        <StyledLogo />
      </Link>

      {authStore.profile && (
        <NavLinks>
          {isAdmin && (
            <Button
              size="small"
              width="auto"
              color="primary"
              variant="outline"
              onClick={() => navigate(ROUTE.NEW_USER)}
            >
              New user
            </Button>
          )}
          <NavLink
            to={ROUTE.UNRATED_PLAYERS}
            badge={playerStore.unratedPlayers.length > 0}
          >
            <JerseyIcon fill={theme.palette.light[50]} />
          </NavLink>
          <StyledLink to={ROUTE.PROFILE}>
            <Avatar />
          </StyledLink>
        </NavLinks>
      )}
    </Wrapper>
  );
};

export default observer(Navbar);
